<template>
  <div class="wrapper">
    <div class="navBar">
      <div @click="$router.go(-1)" class="back">
        <svg-icon class="leftBtn" iconClass="back"></svg-icon>
      </div>
      <div class="title">推广设置</div>
      <div class="rightBtn"></div>
    </div>
    <div class="content">
      <AdvSwiper :advList="advList" v-if="advList.length" class="config_adv" />
      <div class="config_video_info">
        <div class="config_cover">
          <ImgDecypt :src="setTopData.promoteInfo.cover" />
        </div>
        <div class="video_info">
          <div class="title">{{ setTopData.promoteInfo.title }}</div>
          <div class="user_name">@{{ setTopData.promoteInfo.user.name }}的视频</div>
        </div>
      </div>
      <div class="config_info">
        <h2>推广设置</h2>
        <div class="config_info_item time_box">
          <div class="config_left">推广时长</div>
          <div class="config_right" @click="changeDay">
            <span>{{ info.length > 0 ? info[curIndex].popDays : 0 }}天</span>
            <svg-icon icon-class="toRight" class="toRight" />
          </div>
        </div>
        <div class="config_info_item">
          <div class="config_left">金额</div>
          <div class="config_right">{{ info.length > 0 ? info[curIndex].price : 0 }}金币</div>
        </div>
      </div>
    </div>
    <div class="footerBox">
      <div class="footer">
        <div class="footer_left">
          <div class="amount">{{ info.length > 0 ? info[curIndex].price : 0 }}金币</div>
          <div class="balance">余额: {{ walletInfo.amount || 0 }}金币</div>
        </div>
        <div class="topromote" @click="clickPromote">去推广</div>
      </div>
    </div>
    <van-popup v-model="show" position="bottom" round :style="{ height: '45%' }" class="pop_config">
      <h2>推广时长</h2>
      <div class="pop_content">
        <div :class="['day_item', { active: index == curIndex }]" v-for="(item, index) in info" :key="item.id" @click="selectItem(item, index)">
          {{ item.popDays }}天
        </div>
      </div>
    </van-popup>
  </div>
</template>
<script>
import { queryPopConfigList } from '@/api/user/index.js';
import { publisherTop } from '@/api/mine/index.js';
import { AdType, getAdItem } from '@/utils/getConfig';
import AdvSwiper from '@/components/AdvSwiper';
import ImgDecypt from '@/components/ImgDecypt';
import { mapGetters } from 'vuex';
import { Toast } from 'vant';
export default {
  components: {
    AdvSwiper,
    ImgDecypt,
  },
  data() {
    return {
      advList: [],
      show: false,
      info: [],
      curIndex: 0,
    };
  },
  created() {
    this.advList = getAdItem(AdType.TO_PROMOTE);
    this.getData();
  },
  computed: {
    ...mapGetters({
      walletInfo: 'walletInfo',
      setTopData: 'setTopData',
    }),
  },
  methods: {
    async getData() {
      this.$store.commit('app/SET_LOADING', true);
      try {
        let res = await this.$Api(queryPopConfigList);
        if (res.code == 200) {
          this.info = res.data || [];
        }
      } finally {
        this.$store.commit('app/SET_LOADING', false);
      }
    },
    async clickPromote() {
      if (this.walletInfo.amount < this.info[this.curIndex].price) return Toast('余额不足');
      this.$store.commit('app/SET_LOADING', true);
      try {
        if (this.info.length == 0 || !this.setTopData.id) {
          Toast('用户信息有误');
          return;
        }
        let popId = this.info[this.curIndex].popId;
        let data = {
          videoId: this.setTopData.id,
          actionPop: 1,
          popId: popId,
          type: this.setTopData.type,
          // action: this.setTopData.action,
        };
        let res = await this.$Api(publisherTop, data);
        if (res.code == 200) {
          Toast('提交成功，等待审核');
          this.$store.dispatch('user/getWallet');
          setTimeout(() => {
            this.$router.back();
          }, 0);
          return;
        }
        Toast(res.tip || '提交失败');
      } finally {
        this.$store.commit('app/SET_LOADING', false);
      }
    },
    selectItem(item, index) {
      this.curIndex = index;
      this.show = false;
    },
    changeDay() {
      this.show = true;
    },
  },
};
</script>
<style lang="scss">
.wrapper {
  width: 100%;
  height: 100%;
  overflow: hidden;
  box-sizing: border-box;
  .navBar {
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    background-color: #fff;
    box-sizing: border-box;
    border-bottom: 1px solid #e0e0e0;
    .back {
      width: 80px;
      display: flex;
      align-items: center;

      .leftBtn {
        width: 24px;
        height: 24px;
        font-size: 24px;
      }
    }

    .title {
      flex: 1;
      font-size: 18px;
      text-align: center;
      color: #000;
    }

    .rightBtn {
      width: 80px;
    }
  }
  .content {
    padding: 12px 12px 0px;
    .config_adv {
      height: 86px;
      border-radius: 4px;
      overflow: hidden;
      .swiper-container {
        height: 100%;
        img {
          height: 100%;
        }
      }
    }
    .config_video_info {
      background: #fff;
      display: flex;
      margin: 14px 0;
      padding: 16px 13px;
      border-radius: 4px;
      .config_cover {
        width: 78px;
        height: 78px;
        flex-shrink: 0;
        border-radius: 8px;
        overflow: hidden;
      }
      .video_info {
        margin-left: 11px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        font-size: 14px;
        .title {
          display: -webkit-box;
          overflow: hidden;
          white-space: normal !important;
          text-overflow: ellipsis;
          word-wrap: break-word;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
        }
        .user_name {
          color: rgb(255, 103, 143);
          font-size: 12px;
        }
      }
    }
    .config_info {
      background: #fff;
      display: flex;
      flex-direction: column;
      margin: 14px 0;
      padding: 16px 13px;
      font-size: 16px;
      border-radius: 4px;
      h2 {
        padding-bottom: 20px;
      }
      .config_info_item {
        display: flex;
        justify-content: space-between;
        .config_left {
          color: #b7b7b7;
        }
        .config_right {
          color: rgb(255, 103, 143);
          display: flex;
          align-items: center;
          .toRight {
            font-size: 20px;
          }
        }
      }
      .time_box {
        padding-bottom: 20px;
      }
    }
  }
  .footerBox {
    position: absolute;
    box-sizing: border-box;
    bottom: 0;
    width: 100%;
    height: 110px;
    background: #fff;
    display: flex;
    justify-content: space-between;
    padding: 14px 12px;
    .footer {
      height: 46px;
      width: 100%;
      background-color: rgb(243, 243, 243);
      border-radius: 46px;
      overflow: hidden;
      display: flex;
      .footer_left {
        flex: 1;
        display: flex;
        align-items: center;
        .amount {
          font-size: 18px;
          font-weight: 800;
          margin-left: 20px;
        }
        .balance {
          font-size: 12px;
          margin: 4px 0 0 8px;
          color: rgb(153, 153, 153);
        }
      }
      .topromote {
        width: 138px;
        height: 100%;
        background-color: rgb(255, 103, 143);
        font-size: 16px;
        color: #fff;
        line-height: 46px;
        text-align: center;
      }
    }
  }
  .pop_config {
    background-color: #f8f8f8;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    h2 {
      font-size: 16px;
      color: #333333;
      text-align: center;
      padding: 21px 0 17px 0;
      border-bottom: 1px solid #eeeeee;
      font-weight: 500;
    }
    .pop_content {
      flex: 1;
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;
      .active {
        color: rgb(255, 103, 143) !important;
      }
      .day_item {
        text-align: center;
        color: #666666;
        height: 50px;
        line-height: 60px;
        font-size: 16px;
      }
    }
  }
}
</style>
